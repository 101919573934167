import React, { useState } from "react"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { graphql, Link } from "gatsby"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Player } from "@lottiefiles/react-lottie-player"
import { Modal } from "antd"
import ImageButton from "../components/v2023/UI/ImageButton/image-button"
import IdentityTryNowModal from "../components/v2023/Identity/try-now-modal"
import Card from "../components/v2023/UI/Card/card"



const IdentityVerification = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page

    const [toolSelected, setToolSelected] = useState(page.incorporates_clients_items[0])
    const [toolInterval, setToolInterval] = useState(true)
    const [stepIndexPulse, setStepIndexPulse] = useState(0)
    const [stepSelected, setStepSelected] = useState(page.demo_steps[0])
    const [showStepModal, setShowStepModal] = useState(false)
    const [allNeedSelected, setAllNeedSelected] = useState(page.onboarding_items[0])
    const [flowType, setFlowType] = useState(FlowType.camera)
    const [flowDescription, setFlowDescription] = useState(page.camera_description)
    const [flowSteps, setFlowSteps] = useState(page.camera_steps)
    const [flowIndexPulse, setFlowIndexPulse] = useState(0)
    const [showFlowModal, setShowFlowModal] = useState(false)
    const [flowStepSelected, setFlowStepSelected] = useState(page.camera_steps[0])
    const [characteristicSelected, setCharacteristicSelected] = useState(page.characteristics[0])


    const openStepModal = (index) => {
        setStepSelected(page.demo_steps[index])
        setShowStepModal(true)
        setStepIndexPulse(index !== (page.demo_steps.length - 1) ? index + 1 : index)
        setFlowIndexPulse(0)
    }

    const nextTool = (allowInterval = true) => {
        const index = page.incorporates_clients_items.indexOf(toolSelected)

        if (index === (page.incorporates_clients_items.length - 1)) {
            setToolSelected(page.incorporates_clients_items[0])
        } else {
            setToolSelected(page.incorporates_clients_items[index + 1])
        }

        setToolInterval(allowInterval)
    }

    const prevTool = () => {
        const index = page.incorporates_clients_items.indexOf(toolSelected)

        if (index === 0) {
            setToolSelected(page.incorporates_clients_items[page.incorporates_clients_items.length - 1])
        } else {
            setToolSelected(page.incorporates_clients_items[index - 1])
        }
    }

    const setFlow = (type) => {
        setFlowType(type)
        setFlowDescription(type === FlowType.camera ? page.camera_description : page.nfc_description)
        setFlowSteps(type === FlowType.camera ? page.camera_steps : page.nfc_steps)
        setFlowIndexPulse(0)
    }

    const openFlowModal = (index) => {
        setFlowStepSelected(flowSteps[index])
        setShowFlowModal(true)
        setFlowIndexPulse(index !== (flowSteps.length - 1) ? index + 1 : index)
    }

    const getFlowStepSelectedHeader = () => {
        if (flowStepSelected.type === "Text") {
            return <div className="complex-header">
                <div className="tag-image">
                    <GatsbyImage alt={flowStepSelected.image.alternativeText} image={getImage(flowStepSelected.image.localFile)} />
                </div>
                <div className="complex-header__info">
                    <h4>{flowStepSelected.title}</h4>
                    <div className="complex-header__info__details">
                        {flowStepSelected.success && <div className="tag tag--status"><i className="icon-check-circle"></i> {flowStepSelected.success}</div>}
                        {flowStepSelected.process && <span className="flex ai-center"><i className="icon-clock m-r-4"></i> {flowStepSelected.process}</span>}
                    </div>
                </div>
            </div>
        }

        return <>
            <h3>{flowStepSelected.title}</h3>
            <ReactMarkdown children={flowStepSelected.description} rehypePlugins={[rehypeRaw]} />
        </>
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section  main__section--100 main__section--100-hero color-section purple-dark">
                    <div className="container">
                        <div className="tag tag--highlight" data-aos="fade-up">{page.tag}</div>
                        <div className="container__text" data-aos="fade-up">
                            <h1 className="merriweather">{page.title}</h1>
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="button__wrapper" data-aos="fade-up">
                            <IdentityTryNowModal
                                lang={lang}
                                button={page.try_now_button}
                                text={page.try_now_text}
                                title={page.try_now_title}
                                description={page.try_now_description}
                                form_name={page.try_now_form_name}
                                form_surname={page.try_now_form_surname}
                                form_email={page.try_now_form_email}
                                form_tc={page.try_now_form_tc}
                                form_tc_description={page.try_now_form_tc_description}
                                form_button={page.try_now_form_button}
                                code_title={page.try_now_code_title}
                                code_code={page.try_now_code_code}
                                code_button={page.try_now_code_button}
                                qr_title={page.try_now_qr_title}
                                qr_title_mobile={page.try_now_qr_title_mobile}
                                qr_description={page.try_now_qr_description}
                                qr_button={page.try_now_qr_button}
                            />
                            <Link to={page.request_demo.url}>
                                <button className="button button--secondary button--secondary--ghost button--xl">{page.request_demo.text}</button>
                            </Link>
                        </div>
                        <div data-aos="fade-up">
                            <LinkButton button={page.know_our_plans} className={"link--big link--ghost"} extras={<i className="icon-arrow-right"></i>} />
                        </div>
                    </div>
                    <div className="container container--np" data-aos="fade-up">
                        <div className="browser-image">
                            {lang === "es" && <div className="hidden-lg">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/grafismo.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__desktop"
                                />
                            </div>}
                            {lang === "en" && <div className="hidden-lg">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/grafismo-en.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__desktop"
                                />
                            </div>}
                            <div className="show-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/grafismo-mobile.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__mobile"
                                />
                            </div>
                            {lang === "es" && <div className="show-lg hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/grafismo-tablet.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__tablet"
                                />
                            </div>}
                            {lang === "en" && <div className="show-lg hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/grafismo-tablet-en.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__tablet"
                                />
                            </div>}
                            <div className="browser-image__buttons browser-image__buttons--identity">
                                {page.demo_steps.map((item, index) => {
                                    return <button
                                        key={index}
                                        className={`button-icon button-icon--number ${index === stepIndexPulse ? "button-icon--number--pulse" : ""}`}
                                        onClick={() => openStepModal(index)}>
                                        {item.order}
                                    </button>
                                })}

                                <Modal
                                    centered
                                    onCancel={() => setShowStepModal(false)}
                                    visible={showStepModal}
                                    title={<>
                                        <h3>{stepSelected.title}</h3>
                                        <ReactMarkdown children={stepSelected.description} rehypePlugins={[rehypeRaw]} />
                                        {stepSelected.image_button && <ImageButton button={stepSelected.image_button} />}
                                    </>}
                                    width={"100%"}
                                    footer={null}
                                    className="ant-modal--green"
                                >
                                    <GatsbyImage alt={stepSelected.image.alternativeText} image={getImage(stepSelected.image.localFile)} />
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <div className="container" data-aos="fade-up">
                        <div className="grid-sm-4">
                            {page.metrics.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <div className="stat" key={index} {...animation}>
                                    <div className="title-xl">{item.title}</div>
                                    <span><ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} /></span>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--md">
                    <div className="container">
                        <div className="container__50">
                            <div className="carrousel carrousel--slim">
                                {page.incorporates_clients_items.map((item, index) => (
                                    <div className={`carrousel__content ${toolSelected.id === item.id ? "carrousel__content--show" : ""}`} key={index}>
                                        <h2>
                                            {page.incorporates_clients_title} <span className="typewriter" style={{ "--n": [...item.title].length }}>{item.title}</span>
                                        </h2>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                ))}
                                <div className="carrousel__actions">
                                    <div className="carrousel__actions__dots">
                                        {page.incorporates_clients_items.map((item, index) => (
                                            <span className={`dots__dot ${item.id === toolSelected.id ? "dots__dot--active" : ""}`} key={index}
                                                onClick={() => setToolSelected(item)} />
                                        ))}
                                    </div>
                                    <div className="carrousel__actions__buttons">
                                        <button className="button-icon button-icon--secondary button-icon--lg" onClick={prevTool}>
                                            <i className="icon-arrow-left"></i>
                                        </button>
                                        <button className="button-icon button-icon--secondary button-icon--lg" onClick={() => nextTool(false)}>
                                            <i className="icon-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container__50 hidden-lg">
                            {page.incorporates_clients_items.map((item, index) => {
                                return item.id === toolSelected.id && <Player
                                    key={index}
                                    src={item.anim.localFile.publicURL}
                                    autoplay={true}
                                    keepLastFrame={true}
                                    background={"transparent"}
                                />
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tabs">
                            <h2 className={`tabs__tab ${flowType === 'camera' ? 'tabs__tab--active' : ''}`} onClick={() => setFlow(FlowType.camera)}>
                                {page.camera_title}
                            </h2>
                            <h2 className={`tabs__tab ${flowType === 'nfc' ? 'tabs__tab--active' : ''}`} onClick={() => setFlow(FlowType.nfc)}>
                                {page.nfc_title}
                            </h2>
                        </div>
                        <div className="container__text">
                            <div className="text--black">
                                <ReactMarkdown children={flowDescription} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="numbered-image">
                            {lang === "es" && flowType === FlowType.camera && <div className="hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/camara-es.png"}
                                    alt="steps identity"
                                    placeholder="blurred"
                                />
                            </div>}
                            {lang === "en" && flowType === FlowType.camera && <div className="hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/camara-en.png"}
                                    alt="steps identity"
                                    placeholder="blurred"
                                />
                            </div>}
                            {lang === "es" && flowType === FlowType.camera && <div className="show-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/camara-2-es.png"}
                                    alt="steps identity"
                                    placeholder="blurred"
                                />
                            </div>}
                            {lang === "en" && flowType === FlowType.camera && <div className="show-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/camara-2-en.png"}
                                    alt="steps identity"
                                    placeholder="blurred"
                                />
                            </div>}

                            {lang === "es" && flowType === FlowType.nfc && <div className="hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/nfc-es.png"}
                                    alt="steps identity"
                                    placeholder="blurred"
                                />
                            </div>}
                            {lang === "en" && flowType === FlowType.nfc && <div className="hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/nfc-en.png"}
                                    alt="steps identity"
                                    placeholder="blurred"
                                />
                            </div>}
                            {lang === "es" && flowType === FlowType.nfc && <div className="show-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/nfc-2-es.png"}
                                    alt="steps identity"
                                    placeholder="blurred"
                                />
                            </div>}
                            {lang === "en" && flowType === FlowType.nfc && <div className="show-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/identity/nfc-2-en.png"}
                                    alt="steps identity"
                                    placeholder="blurred"
                                />
                            </div>}

                            <div className={`numbered-image__numbers hidden-sm ${flowType === FlowType.camera ? 'numbered-image__numbers--camera' : 'numbered-image__numbers--nfc'}`}>
                                {flowSteps.map((item, index) => {
                                    return <button
                                        key={index}
                                        className={`button-icon button-icon--number ${index === flowIndexPulse ? "button-icon--number--pulse" : ""}`}
                                        onClick={() => openFlowModal(index)}>
                                        {item.order}
                                    </button>
                                })}

                                <Modal
                                    centered
                                    onCancel={() => setShowFlowModal(false)}
                                    visible={showFlowModal}
                                    title={getFlowStepSelectedHeader()}
                                    width={"100%"}
                                    footer={null}
                                    className={flowStepSelected.type === "Image" ? "ant-modal--green" : "ant-modal--complex-header"}
                                >
                                    {flowStepSelected.type === "Image" && <GatsbyImage alt={flowStepSelected.image.alternativeText} image={getImage(flowStepSelected.image.localFile)} />}
                                    {flowStepSelected.type === "Text" && <ReactMarkdown children={flowStepSelected.description} rehypePlugins={[rehypeRaw]} />}
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tabs tabs--pills-big">
                            {page.characteristics.map((item, index) => (
                                <div className={`tabs__tab ${characteristicSelected.id === item.id ? 'tabs__tab--active' : ''}`} key={index} onClick={() => setCharacteristicSelected(item)}>
                                    {item.tag}
                                </div>
                            ))}
                        </div>
                        {page.characteristics.map((item, index) => {
                            return characteristicSelected.id === item.id && <div className="tabs__content-column" key={index}>
                                <div className="tabs__content-column__50">
                                    <h2>{item.title}</h2>
                                    <div className="container__text">
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                    {item.image_button && <ImageButton className="link-image" button={item.image_button} />}
                                </div>
                                <div className="tabs__content-column__50">
                                    <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className="main__section  main__section--100">
                    <div className="container">
                        <h2>{page.onboarding_title}</h2>
                        <div className="item-group">
                            <div className="item-group__image hidden-lg" data-aos="fade-right">
                                <GatsbyImage alt={allNeedSelected.icon.alternativeText} image={getImage(allNeedSelected.icon.localFile)} />
                            </div>
                            <div className="item-group__items">
                                {page.onboarding_items.map((item, index) => (
                                    <div className={`item item--unboxed ${item.id === allNeedSelected.id ? "item--active" : ""}`} key={index} onClick={() => setAllNeedSelected(item)}>
                                        <div className="item__header">
                                            <i className="icon-check-circle"></i>
                                            <h5>{item.title}</h5>
                                        </div>
                                        <div className="item__body">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                            <div className="item__body__image show-lg">
                                                <GatsbyImage alt={allNeedSelected.icon.alternativeText} image={getImage(allNeedSelected.icon.localFile)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section">
                    <div className="container">
                        <div className="grid-md">

                            <StaticImage
                                src={"../new/resources/certifications/9001.svg"}
                                alt="AENOR ISO 9001"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/27701.svg"}
                                alt="AENOR ISO 27701"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/eidas.svg"}
                                alt="eidas"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/iqnet.svg"}
                                alt="iqnet"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/fido.svg"}
                                alt="fido"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/gdpr.svg"}
                                alt="gdpr"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/kyc.svg"}
                                alt="kyc"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/certificado-digital.svg"}
                                alt="certificado digital"
                                placeholder="blurred"
                            />
                        </div>
                    </div>
                </div>
                <div className="main__section">
                    <div className="container container--np">
                        <div className="tile tile--complex-gray">
                            <div className="tile__body">
                                <h2>{page.results_title}</h2>
                                <div className="tile__body__columns">
                                    <div className="tile">
                                        <div className="tile__body">
                                            <div className="tile__body__image">
                                                <GatsbyImage alt={page.results_items[0].icon.alternativeText} image={getImage(page.results_items[0].icon.localFile)} />
                                            </div>
                                            <div className="tile__body__text">
                                                <ReactMarkdown children={page.results_items[0].description} rehypePlugins={[rehypeRaw]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tile__body__list">
                                        {page.results_items.slice(1).map((item, index) => (
                                            <div className="list__list-item" key={index}>
                                                <div className="list__list-item__image">
                                                    <GatsbyImage alt={item.icon.alternativeText} image={getImage(item.icon.localFile)} />
                                                </div>
                                                <div className="list__list-item__text">
                                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.ad_hoc_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.ad_hoc_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                    <div className="infinite-carrousel">
                        <div className="infinite-carrousel__track">
                            {page.ad_hoc_logos.map((item, index) => (
                                <div className="infinite-carrousel__track__logo" key={index}>
                                    <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                                </div>
                            ))}
                            {page.ad_hoc_logos.map((item, index) => (
                                <div className="infinite-carrousel__track__logo" key={index}>
                                    <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                                </div>
                            ))}
                            {page.ad_hoc_logos.map((item, index) => (
                                <div className="infinite-carrousel__track__logo" key={index}>
                                    <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="container">
                        <div className="grid-lg-3-h">
                            {page.ad_hoc_items.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <Card
                                    key={index}
                                    name={item.title}
                                    description={`${item.description}`}
                                    animation={animation}
                                />
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container ">
                        <div className="tile">
                            <h3>{page.cta_title}</h3>
                            <div className="tile__body">
                                <a href={page.cta_button.url} target="_blank" rel="noreferrer">
                                    <GatsbyImage alt={page.cta_button.image.alternativeText} image={getImage(page.cta_button.image.localFile)} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <ReadyToTalk lang={lang} />

            </main>
        </NewLayout>

    )
}

export default IdentityVerification

export const FlowType = {
    camera: "camera",
    nfc: "nfc"
}

export const identityVerificationQuery = graphql`
    query ($langKey: String) {
        page: strapi2023Identity ( locale: { eq: $langKey } ) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            tag
            title
            description
            try_now_button
            try_now_text
            try_now_title
            try_now_description
            try_now_form_name
            try_now_form_surname
            try_now_form_email
            try_now_form_tc
            try_now_form_tc_description
            try_now_form_button
            try_now_code_title
            try_now_code_code
            try_now_code_button
            try_now_qr_title
            try_now_qr_title_mobile
            try_now_qr_description
            try_now_qr_button
            request_demo {
                text
                url
                blank
            }
            know_our_plans {
                text
                url
                blank
            }
            demo_steps {
                order
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                width: 650
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                image_button {
                    url
                    blank
                    image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData (
                                    width: 120
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
            metrics {
                title
                description
            }
            incorporates_clients_title
            incorporates_clients_items {
                id
                title
                description
                anim {
                    localFile {
                        publicURL
                    }
                }
            }
            camera_title
            camera_description
            camera_steps {
                order
                title
                success
                process
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                width: 650
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                type
            }
            nfc_title
            nfc_description
            nfc_steps {
                order
                title
                success
                process
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                width: 650
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                type
            }
            characteristics {
                id
                tag
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                width: 608
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                image_button {
                    url
                    blank
                    image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData (
                                    width: 150
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
            onboarding_title
            onboarding_items {
                id
                order
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                width: 520
                                height: 565
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            results_title
            results_items {
                order
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                width: 280
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            ad_hoc_title
            ad_hoc_description
            ad_hoc_logos {
                alt
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                width: 88
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            ad_hoc_items {
                title
                description
            }
            cta_title
            cta_button {
                url
                blank
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                width: 286
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
        }
    }
`
