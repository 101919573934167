import React, { useEffect, useState } from "react"
import { Modal } from "antd"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { FormStatus, useFormInput } from "../../../utils/gatsby-helpers"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import QRCode from "react-qr-code"

const IdentityTryNowModal = ({
                                 lang,
                                 button,
                                 text,
                                 title,
                                 description,
                                 form_name,
                                 form_surname,
                                 form_email,
                                 form_tc,
                                 form_tc_description,
                                 form_button,
                                 code_title,
                                 code_code,
                                 code_button,
                                 qr_title,
                                 qr_title_mobile,
                                 qr_description,
                                 qr_button
                             }) => {
    const [showModal, setShowModal] = useState(false)
    const [step, setStep] = useState(Steps.form)
    const [qrValue, setQrValue] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [width, setWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0)

    const { executeRecaptcha } = useGoogleReCaptcha()
    const name = useFormInput("")
    const surname = useFormInput("")
    const email = useFormInput("")
    const code = useFormInput("")
    const idioma = useFormInput(lang.toUpperCase())

    const handleDataSubmit = async (e) => {
        e.preventDefault()

        if (!executeRecaptcha) {
            console.error("executeRecaptcha fail")
            return
        }

        const token = await executeRecaptcha("homepage")
        const json = {
            "firstname": name.value,
            "lastname": surname.value,
            "email": email.value,
            "locale": lang,
            "idioma": idioma.value,
            "g-recaptcha-response": token,
            "tipo_mql": "Product Users",
            "tipo_product_users": "No Product Sign Up",
            "tipo_prueba_identity": lang === "en" ? "KYC EN" : "KYC",
        }
        const body = JSON.stringify(json)

        setIsLoading(true)

        fetch(process.env.IDENTITY_FORM_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: body
        }).then(response => {
            if (response.ok) {
                return response.json()
            }

            throw new Error("Something went wrong")
        }).then(data => {
            setStep(Steps.code)
            setIsLoading(false)
        }).catch(error => {
            console.error(error)
            setIsLoading(false)
        })
    }

    const handleCodeSubmit = async (e) => {
        e.preventDefault()

        if (!executeRecaptcha) {
            console.error("executeRecaptcha fail")
            return
        }

        const token = await executeRecaptcha("homepage")
        const json = {
            "email": email.value,
            "otp": code.value,
            "g-recaptcha-response": token,
        }
        const body = JSON.stringify(json)

        setIsLoading(true)

        fetch(process.env.IDENTITY_OTP_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: body
        }).then(response => {
            if (response.ok) {
                return response.json()
            }

            throw new Error("Something went wrong")
        }).then(data => {
            setStep(Steps.qr)
            setQrValue(data.url)
            setIsLoading(false)
        }).catch(error => {
            console.error(error)
            setIsLoading(false)
        })
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return <>
        <div className="button__wrapper__button-with-info">
            <button className="button button--ghost button--xl" onClick={() => setShowModal(true)}>{button}</button>
            <span>{text}</span>
        </div>

        <Modal
            centered
            onCancel={() => setShowModal(false)}
            afterClose={() => setStep(Steps.form)}
            visible={showModal}
            width={"100%"}
            footer={null}
        >
            {step === Steps.form && <div>
                <h3>{title}</h3>
                <ReactMarkdown children={description} rehypePlugins={[rehypeRaw]} />

                <form className="form" onSubmit={handleDataSubmit}>
                    <div className="form__group">
                        <div style={{ position: "absolute", height: 0 }}>
                            <input className="input" type="text" name="idioma" {...idioma} style={{ visibility: "hidden" }} />
                            <input className="input" type="text" name="tipo_mql" value="Product Users" style={{ visibility: "hidden" }} />
                            <input className="input" type="text" name="tipo_product_users" value="No Product Sign Up" style={{ visibility: "hidden" }} />
                            <input className="input" type="text" name="tipo_prueba_identity" value={lang === "en" ? "KYC EN" : "KYC"} style={{ visibility: "hidden" }} />
                        </div>

                        <input className="input" type="text" name="firstname" required placeholder={form_name} disabled={isLoading} {...name} />
                        <input className="input" type="text" name="lastname" required placeholder={form_surname} disabled={isLoading} {...surname} />
                        <input className="input" type="email" name="email" required placeholder={form_email} disabled={isLoading} {...email} />
                    </div>

                    <div className="form__checkbox">
                        <div className="checkbox-group">
                            <input className="checkbox" type="checkbox" id="checkbox_id" required />
                            <label htmlFor="checkbox_id">
                                <ReactMarkdown children={form_tc} rehypePlugins={[rehypeRaw]} />
                            </label>
                        </div>
                    </div>

                    <div className="form__text">
                        <ReactMarkdown children={form_tc_description} rehypePlugins={[rehypeRaw]} />
                    </div>

                    <div className="form__button">
                        <button type="submit" className="button" disabled={isLoading}>{form_button}</button>
                    </div>
                </form>
            </div>}

            {step === Steps.code && <div>
                <h3>{code_title}</h3>

                <form className="form" onSubmit={handleCodeSubmit}>
                    <div className="form__group form__group--center">
                        <input className="input" type="text" name="code" required placeholder={code_code} disabled={isLoading} {...code} />
                    </div>

                    <div className="form__button">
                        <button type="submit" className="button" disabled={isLoading}>{code_button}</button>
                    </div>
                </form>
            </div>}

            {step === Steps.qr && <div>
                {!isMobile && <h3>{qr_title}</h3>}
                {isMobile && <h3>{qr_title_mobile}</h3>}
                <ReactMarkdown children={qr_description} rehypePlugins={[rehypeRaw]} />
                {qrValue && !isMobile && <QRCode value={qrValue} size={150} className="qr-image" />}
                {qrValue && isMobile && <a href={qrValue} className="button" target="_blank" rel="noreferrer">{qr_button}</a>}
            </div>}
        </Modal>
    </>
}

export default IdentityTryNowModal

export const Steps = {
    form: "form",
    code: "code",
    qr: "qr"
}
